* {
    margin: 0;
    box-sizing: border-box;
    color: white;
    
}

@font-face {
    font-family:'Merianda' ;
    src: url("../fonts/Merianda.ttf") format('woff2');
}

.landingpage {
    width: 100%;
    height: 100vh;
    margin-top: -30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: relative;
    transition: 0.5s;
    font-family: "Merianda";
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
   
    background-image: url("../images/chemise.jpg")  ;
    background-image: linear-gradient(90deg, #4653926b, #152a926b);
 

   
   
}

.video-bg {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: -1;
    object-fit: cover;
  
 
    
    
}

.bg-overlay {
    height: 100%;
    width: 100%;
 
    position: absolute;
    top: 0;
    z-index: 0;
}

.navbar {
    width: 100%;
    height: 6em;
    border-bottom: 2px solid white;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;
}
.bg-tree {
    border-bottom: 2px solid white;
    width: 50%;

}

.menu {
    height: 3em;
    width: 4em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 2em;
    cursor: pointer;
}



.menu div {
    width: 90%;
    height: 0.25em;
    background-color: white;

}

.menu div:nth-of-type(2) {width: 40%;}


.home-text {
    width:80%;
    height: 50%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 
    z-index: 2;

    text-align: center;
  
}
.imageiso{
    background-color: aqua;
    align-items: center;
    margin-top: 15px;


}



.home-text h1 {
    font-size: 120px;
    letter-spacing: 0.3em;
    text-shadow: 10px 10px 25px rgba(0,0,0,0.95);
    color: white;
    font-family: 'Ramabhadra', sans-serif;
    font-weight: bold;
    

}

.home-text p {
    font-size: 28px;
    letter-spacing: 0.3em;
    text-shadow: 0px 0px 16px rgba(0,0,0,0.95);
    color: white;
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    align-items: center;
    justify-content: center;
    text-align: center;

}

@media only screen and (max-width: 600px) and (min-width: 400px)  {
    .home-text {
        width:100%;
        height: 50%;

        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
     
        z-index: 2;
    
        text-align: center;
      
    }
    .home-text h1 {
        font-size: 60px;
        width: 100%;
        letter-spacing: 0.3em;
        text-shadow: 10px 10px 25px rgba(0,0,0,0.95);
        color: white;
        font-family: 'Ramabhadra', sans-serif;
        font-weight: bold;
        margin-bottom: -10px;
        align-items: center;
   
        text-align: center;
        
    
    }
    .diplayclass{
        
        visibility: hidden;
        
    }
    .home-text p {
        font-size: 18px;
        letter-spacing: 0.3em;
        text-shadow: 0px 0px 16px rgba(0,0,0,0.95);
        color: white;
        font-family: 'Raleway', sans-serif;
        font-weight: 800;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: -5px;
    
    }


}


@media only screen and (max-width: 400px)   {
    .home-text {
        width:100%;
        height: 50%;
        
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
     
        z-index: 2;
    
        text-align: center;
      
    }
    .home-text h1 {
        font-size: 60px;
        width: 100%;
        letter-spacing: 0.3em;
        text-shadow: 10px 10px 25px rgba(0,0,0,0.95);
        color: white;
        font-family: 'Ramabhadra', sans-serif;
        font-weight: bold;
        margin-bottom: -5px;
        align-items: center;
   
        text-align: center;
      
        
        
    
    }
    .diplayclass{
        
        visibility: hidden;
        
    }
    .home-text p {
        font-size: 14px;
        letter-spacing: 0.3em;
        text-shadow: 0px 0px 16px rgba(0,0,0,0.95);
        color: white;
        font-family: 'Raleway', sans-serif;
        font-weight: 800;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 10px;

       
    
    }
 


}


h1 {
    font-size: 6em;
 
}

p {
    justify-self: flex-end;
    font-size: 2em;
}

.home-btn {
    width: 6em;
    height: 2em;

    border-radius: 0.25em;
    background-color: rgba(255, 255, 255, 0.457);
    z-index: 1;
    font-size: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}




/* ---------- Animation --------------- */
@keyframes fade {
    0% {
        opacity: 0;
        margin-left: 3em;
    }
    100% {
        opacity: 1;
    }
}