/* mot du president */


.description-wrapperprecedent p {
    font-size: 16px;
    color: #1c1c1b;
  }

  
.description-wrapperprecedent li
 {
  font-size: 16px;
  color: #1c1c1b;
  margin-bottom: 10px;
}



