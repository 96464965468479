
.content_wrapper{
  width:100%;
  height: 100vh;
  margin:0 auto;
  position: fixed;


  justify-content: center;
  align-items: center;
  
  background-image: url('../images/bgground/foire2024.jpg');
  background-size: cover;
  
  padding:3%;
 
}

.content_wrapperimg{
  width:100%;
  height: 500px;
  display: flex;
  

  justify-content: center;
  align-items: center;
 

  background-size: auto;
  border-radius: 16px;
 

 
}


.content_wrapper .left_content_wrapper{
  width:100%;
  align-items: center;

  
}
.content_wrapper .left_content_wrapper h2{
    font-size:3.3vw;
    font-weight: 600;
    line-height: 1.3;
    overflow: hidden;
  }
  .content_wrapper .left_content_wrapper h2  span{
      display: inline-block; 
      color: blue;
    } 
    .content_wrapper .left_content_wrapper h2  span.second{
        margin-left:10px;
      }      
    
      .content_wrapper   p{
        font-size:1.02vw;
        color:#333;
        margin:20px 0 30px 0;
      }


      .content_wrapper   .btn_group{
     
        width:70%;
        justify-content: 'space-between';
      }
        .content_wrapper   .btn_group   .btn{

          width:48%;
          height:56px;
          font-size: 20px;
          border-radius: 8px;
          font-weight: 600;
        }
        .content_wrapper   .btn_group .btn_primary{
            background-color: black;
            color:white;
        }
        .content_wrapper   .btn_group .btn_primary     svg{
              margin-left:8px;
            }
          
            .content_wrapper   .btn_group .btn_primary .btn_secondary{
            background-color:white;
            color:black;
            border:1px solid black;
          }
              