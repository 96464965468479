.blog-cardnenew
{


  background-color: white;
  padding: 10px;
  border-radius: 10px;

}
.blog-cardProduit
{


  background-color: white;
  height: 300px;
  padding: 10px;
  padding-top: 80px;
  padding-left: 40px;
  border-radius: 10px;
  justify-items: center;
  justify-content: center;
  position: relative;
  

}
.blog-cardProduit li
{
 padding-left: 20px;
 padding-bottom: 10px;
 font-size: 18px;
 font-weight: 500;
 color: #000000;
 
}

.blog-cardProduitbt
{
 position: absolute;
 bottom: 0px;
 left: 10px;
 
 
}


.blog-cardnew {


  border-radius: 10px;
}
.blog-cardnenew img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

}





.blog-contentnew {
  padding: 15px;
}

.blog-contentnew h5 {
  font-size: 22px;
  font-weight: 500;
  line-height: 35px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: var(--color-131921);
  background-color: white;
  border-radius:  10px ;
  box-shadow: 6px 6px 6px #aaa;
}


.blog-contentnew p.desc {
  font-size: 13px;
  line-height: 22px;
  color: var(--color-777777);
}
.single-blog-cardnew a {
  font-size: 18px;
  color: var(--color-777777);
  margin-bottom: 20px;
}
.single-blog-cardnew h3 {
  font-size: 24px;
  font-weight: 600;
  color: var(--color-1c1c1b);
}
.single-blog-cardnew p {
  color: var(--color-777777);
  font-size: 14px;
}
