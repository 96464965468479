.blog-cardnenew
{


  background-color: white;
  padding: 10px;
  border-radius: 10px;

}


.blog-cardnew {


    border-radius: 10px;
  }





.blog-cardnenew img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

}





.blog-contentnew {
  padding: 15px;
}

.blog-contentnew h5 {
  font-size: 22px;
  font-weight: 500;
  line-height: 35px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: var(--color-131921);
  background-color: white;
  border-radius:  10px ;
  box-shadow: 6px 6px 6px #aaa;
}


