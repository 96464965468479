/* Contact Page */
.contact-inner-wrapper {
    padding: 30px 20px;
    border-radius: 15px;
    background-color: white;
    gap: 15px;
  }
  .contact-inner-wrapper > div {
    width: 100%;
  }
  .contact-title {
    font-size: 26px;
    font-weight: 500;
    line-height: 35px;
    text-align: left;
  }
  .contact-inner-wrapper form .form-control {
    background-color: var(--color-f5f5f7);
    border-radius: 10px;
    border-color: transparent;
    padding: 10px;
  }
  .contact-inner-wrapper ul {
    list-style: none;
  }
  .contact-inner-wrapper address,
  .contact-inner-wrapper a,
  .contact-inner-wrapper p {
    color: var(--color-777777);
  }

  .styles_lightroom__1X2qE{

    
    position: fixed;
  }

 